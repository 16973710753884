import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const initialSchemaData = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'Delta 44',
  image: 'https://www.delta44.pl/static/bg-min-0e34d3917b674be62483505c86fa1f5b.jpg',
  '@id': '',
  priceRange: '',
  url: 'https://www.delta44.pl/',
  description:
    'Butikowy budynek biurowy, przestrzeń do wynajęcia w samym centrum Wrocławia ul. Dąbrowskiego 44 ➤ Wynajem powierzchni biurowej ➤ Sprawdź!',
  disambiguatingDescription:
    'Delta44 to unikalny budynek biurowy zlokalizowany w samym centrum Wrocławia, w niedalekiej odległości znajduje się Stare Miasto oraz Dworzec Centralny PKP. Budynek poza standardowymi powierzchniami oferuje również idealnie doświetlone światłem dziennym antresole.',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Wrocław',
    addressRegion: 'Województwo dolnośląskie',
    postalCode: '50-457',
    streetAddress: 'Dąbrowskiego 44',
    addressCountry: 'PL',
    telephone: '+48 22 307 86 55',
    email: 'katarzyna.slawek@martleycap.com',
  },
  openingHoursSpecification: {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    opens: '08:00',
    closes: '18:00',
  },
  sameAs: ['https://delta44.pl/'],
}

const schemaData = {
  '@type': 'Organization',
  name: 'Delta 44',
  alternateName: 'Delta 44 Wrocław - Powierzchnia do wynajęcia',
  url: 'https://www.delta44.pl/',
  logo: 'https://www.delta44.pl/static/bg-min-0e34d3917b674be62483505c86fa1f5b.jpg',
  sameAs: 'https://uk.linkedin.com/company/martley-capital-group/',
}

const SEO = ({ title, description, image, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || '/'}`,
      }

      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <html lang="pl" />
            <title>{seo.title}</title>
            <meta name="theme-color" content="#000"></meta>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && <meta property="og:type" content="article" />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && <meta property="og:description" content={seo.description} />}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && <meta name="twitter:description" content={seo.description} />}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            <script type="application/ld+json">{JSON.stringify(initialSchemaData)}</script>
            <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
          </Helmet>
        </>
      )
    }}
  />
)
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`
