import React from 'react'
import styled, { keyframes } from 'styled-components'
import { StyledContainer } from '../pages/index'
import Img from 'gatsby-image'
import { StyledSeparator } from './Openinig'
import plan from '../data/images/floor.jpg'
import { success, fontColor } from './variables'
import Chevron from './svg/Chevron'

interface AreaProps {
  data: {
    areaDesc: string
    areaHeader: string
    plansLabel: string
    sectionTitle: string
    sectionTitleInline: string
  }
  planImg: {
    childImageSharp: {
      fluid: object
    }
  }
}

const Area: React.FC<AreaProps> = ({ data, planImg }) => {
  const downloadPlan = e => {
    e.target.download = 'Property_Particulars_DELTA_44.pdf'
  }

  return (
    <StyledSection id={`${data.sectionTitle.toUpperCase()}`}>
      <StyledContainer>
        <StyledFlexContainer>
          <StyledHeaderWrapper>
            <Styledh2>{data.sectionTitleInline}</Styledh2>
            <Styledh3>{data.areaHeader}</Styledh3>
          </StyledHeaderWrapper>
        </StyledFlexContainer>
        {/* <StyledImg fluid={planImg.childImageSharp.fluid} /> */}
        <StyledPlanImg src={plan} alt="floor plan" />
        {/* <StyledLink
          onClick={downloadPlan}
          href={"Property_Particulars_DELTA_44.pdf"}
        >
          {data.plansLabel}
          <div style={{ marginLeft: "20px" }}>
            <Chevron
              arrowSize="20px"
              ovalSize="40px"
              color="#fff"
              backgroundColor={success}
            />
          </div>
        </StyledLink> */}
      </StyledContainer>
    </StyledSection>
  )
}

export default React.memo(Area)

const chevronAnimationDown = keyframes`
0% { transform : translateY(0); }
25% { transform : translateY(5px); }
75% { transform : translateY(-5px); }
100% { transform : translateY(0); }
`

const StyledSection = styled.section`
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding-top: 40px;
  }
`
const Styledh2 = styled.h2`
  display: flex;
  align-items: center;
  height: 40px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 5.81px;
  color: ${success}
  text-transform: uppercase;
  margin: 15px;
`
const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-around;
`
const StyledHeaderWrapper = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Styledh3 = styled.h3`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.65px;
  font-weight: bold;
  color: ${fontColor};
  margin-top: 20px;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 35px;
  }
  @media screen and (max-width: 576px) {
    font-size: 28px;
  }
`

const StyledLink = styled.a`
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: 0.29px;
  font-weight: bold;
  color: ${fontColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 90px;
  text-decoration: none;
  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
    font-size: 18px;
  }
  &:hover {
    div {
      @media screen and (min-width: 992px) {
        animation: ${chevronAnimationDown} 0.6s ease-in-out infinite;
        cursor: pointer;
      }
    }
  }
`
const StyledImg = styled(Img)`
  margin-bottom: 40px;
`

const StyledPlanImg = styled.img`
  width: 100%;
  margin-bottom: 40px;
`
