import React from "react";

import ElectricCables from "./svg/ElectricCables";
import Klimatyzacja from "./svg/Klimatyzacja";
import Teletech from "./svg/Teletech";
import Ceiling from "./svg/Ceiling";
import Cctv from "./svg/Cctv";
import Detectors from "./svg/Detectors";
import HVAC from "./svg/HVAC";
import Windows from "./svg/Windows";
import PPOZ from "./svg/PPOZ";
// import Walls from "./svg/Walls";
// import Reception from "./svg/Reception";
// import BMS from "./svg/BMS";
// import Access from "./svg/Access";
import Security from "./svg/Security";
// import Accesibility from "./svg/Accesibility";
// import Cyclists from "./svg/Cyclists";
import BREEAM from "./svg/BREEAM";
// import ATM from "./svg/ATM";

export const primary = "rgba(189, 195, 199, 1)";
export const success = "#ff5722";
export const fontColor = "#002d73";
export const seaBlue = "#00acc1";

export const icons = [
  <ElectricCables />,
  <Teletech />,
  <Cctv />,
  <HVAC />,
  <PPOZ />,
  <Klimatyzacja />,
  <Windows />,
  <Security />,
  <Detectors />,
  <Ceiling />,

  // <Floor />,
  // <Sprinklers />,
  // <Optics />,
  // <FloorBox />,
  // <Walls />,
  // <Windy />,
  // <Reception />,
  // <BMS />,
  // <Access />,
  // <Accesibility />,
  // <Cyclists />,
  // <BREEAM />,
  // <ATM />
];
